import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"
import { CaptchaInfo } from "components/CaptchaManager/CaptchaManager"
import { checkUserIsNotConfirmed } from "utils/requests/checkUserIsNotConfirmed"
import { useEffect, useState } from "react"
import { getCognitoUser } from "utils/requests/getCognitoUser"

export interface CognitoSelectors {
  user: Map<string, string> | null
  session: AmazonCognitoIdentity.CognitoUserSession | null
}

export interface CognitoActions {
  handleSignIn: (
    email: string,
    password: string,
    captchaInfo: CaptchaInfo
  ) => Promise<any>
  handleSignUp: (
    email: string,
    password: string,
    captchaInfo: CaptchaInfo
  ) => Promise<any>
  handleSignOut: () => void
  handleForgotPassword: (email: string) => Promise<any>
  handleNewPasswordCreation: (
    email: string,
    verificationCode: string,
    newPassword: string
  ) => Promise<any>
  handleCodeVerificationRequest: (email: string) => Promise<any>
  handleCodeVerification: (
    userId: string,
    verificationCode: string
  ) => Promise<any>
  getCurrentUser: () => Promise<any>
  updateUserAttributes: (attributes: Record<string, any>) => Promise<any>
}

export interface CognitoHook {
  selectors: CognitoSelectors
  actions: CognitoActions
}

const poolData = {
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID as string,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID as string,
  // Storage: new AmazonCognitoIdentity.CookieStorage({ domain: ".voxies.io" }),
}

export type ForgotPasswordResponseType = "password-reset" | "email-confirmation"

export interface ValidationDataProps {
  turnstileToken: string
  sourceIp: string
  skipKey?: string
}

export function useCognito(): CognitoHook {
  const [user, setUser] = useState<Map<string, string> | null>(null)
  const [session, setSession] =
    useState<AmazonCognitoIdentity.CognitoUserSession | null>(null)
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

  const handleSignIn = (email, password, captchaInfo) => {
    return new Promise((resolve, reject) => {
      const validationData: ValidationDataProps = {
        turnstileToken: captchaInfo.token,
        sourceIp: captchaInfo.ipAddress,
      }

      if (process.env.NEXT_PUBLIC_ENV === "test") {
        validationData.skipKey = process.env.NEXT_PUBLIC_SKIP_KEY as string
      }

      const authenticationData = {
        Username: email,
        Password: password,
        ValidationData: validationData,
        ClientMetadata: {
          sourceIp: captchaInfo.ipAddress,
          turnstileToken: captchaInfo.token,
          environment: process.env.NEXT_PUBLIC_ENV as string,
        },
      }

      const authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)

      const userData = {
        Username: email,
        Pool: userPool,
      }
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async function () {
          resolve(await getCurrentUser())
        },
        onFailure: function (err) {
          if (err.message === "User is not confirmed.") {
            handleCodeVerificationRequest(email)

            resolve("not confirmed")
            return
          }

          console.error("Authentication failed", err)
          reject(err)
        },
      })
    })
  }

  const handleSignUp = (email, password, captchaInfo) => {
    return new Promise((resolve, reject) => {
      const turnstileToken = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: "turnstileToken",
        Value: captchaInfo.token,
      })

      const validationData = [turnstileToken]

      if (process.env.NEXT_PUBLIC_ENV === "test") {
        validationData.push(
          new AmazonCognitoIdentity.CognitoUserAttribute({
            Name: "skipKey",
            Value: process.env.NEXT_PUBLIC_SKIP_KEY as string,
          })
        )
      }

      userPool.signUp(
        email,
        password,
        [],
        validationData,
        (err, result) => {
          if (err) {
            console.error("Sign up failed", err)
            reject(err)
          }

          resolve(result)
        },
        {
          sourceIp: captchaInfo.ipAddress,
          environment: process.env.NEXT_PUBLIC_ENV as string,
        }
      )
    })
  }

  const handleForgotPassword = username => {
    const userData = {
      Username: username,
      Pool: userPool,
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    return new Promise<ForgotPasswordResponseType>((resolve, reject) => {
      checkUserIsNotConfirmed(username)
        .then(isNotConfirmed => {
          if (isNotConfirmed) {
            handleCodeVerificationRequest(username).then(() => {
              resolve("email-confirmation")
            })
            return
          }

          cognitoUser.forgotPassword(
            {
              onSuccess: function () {
                resolve("password-reset")
              },
              onFailure: function (err) {
                reject(err)
              },
            },
            {
              environment: process.env.NEXT_PUBLIC_ENV as string,
            }
          )
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const handleNewPasswordCreation = (email, verificationCode, newPassword) => {
    const userData = {
      Username: email,
      Pool: userPool,
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: function (result) {
          resolve(result)
        },
        onFailure: function (err) {
          reject(err)
        },
      })
    })
  }

  const handleCodeVerificationRequest = email => {
    const userData = {
      Username: email,
      Pool: userPool,
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode(
        function (err, result) {
          if (err) {
            console.error("Code verification request failed", err)
            reject(err)
          }

          resolve(result)
        },
        {
          environment: process.env.NEXT_PUBLIC_ENV as string,
        }
      )
    })
  }

  const handleCodeVerification = async (userId, verificationCode) => {
    const user = await getCognitoUser(userId)
    const email = user?.attributes.get("email")

    return new Promise((resolve, reject) => {
      if (!email) {
        reject(new Error("User not found"))
      }

      const userData = {
        Username: email as string,
        Pool: userPool,
      }
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      cognitoUser.confirmRegistration(
        verificationCode,
        true,
        function (err, result) {
          if (err) {
            console.error("Code verification failed", err)
            reject(err)
          }

          resolve(result)
        }
      )
    })
  }

  const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      if (user !== null) {
        resolve({ user, session })
      }

      const cognitoUser = userPool.getCurrentUser()

      if (cognitoUser != null) {
        return cognitoUser.getSession(function (err, session) {
          if (err) {
            console.error(err.message || JSON.stringify(err))
            return
          }
          if (session.isValid()) {
            setSession(session)
          } else {
            return
          }

          // NOTE: getSession must be called to authenticate user before calling getUserAttributes
          cognitoUser.getUserAttributes(function (err, attributes) {
            if (err) {
              reject(err.message)
            } else if (attributes) {
              const attributesForMap = attributes.map(item => [
                item.Name,
                item.Value,
              ]) as any

              setUser(new Map(attributesForMap))
              resolve({
                session,
                user: new Map(attributesForMap) as Map<string, string>,
              })
            }
          })
        })
      }

      resolve(null)
    })
  }

  const updateUserAttributes = (attributes: Record<string, any>) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser()

      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            console.error(err.message || JSON.stringify(err))
            return
          }

          if (session.isValid()) {
            const attributeList: AmazonCognitoIdentity.CognitoUserAttribute[] =
              []

            for (const key in attributes) {
              attributeList.push(
                new AmazonCognitoIdentity.CognitoUserAttribute({
                  Name: key,
                  Value: attributes[key],
                })
              )
            }

            cognitoUser.updateAttributes(attributeList, function (err, result) {
              if (err) {
                reject(err)
              }
              resolve(result)
            })
          }
        })
      }
    })
  }

  const handleSignOut = () => {
    const cognitoUser = userPool.getCurrentUser()
    setUser(null)
    setSession(null)

    if (cognitoUser != null) {
      cognitoUser.signOut()
    }
  }

  useEffect(() => {
    if (!user) {
      getCurrentUser()
    }
  }, [user])

  return {
    selectors: { user, session },
    actions: {
      handleSignIn,
      handleSignUp,
      handleSignOut,
      handleForgotPassword,
      handleNewPasswordCreation,
      handleCodeVerificationRequest,
      handleCodeVerification,
      getCurrentUser,
      updateUserAttributes,
    },
  }
}
